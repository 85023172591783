.product_listing {
  margin-bottom: 50px;
  padding: 0 15px;
}
.product_listing .productsgrid__product--hidden {
  display: none;
}

html.page--categories h1 {
  color: #d46a00;
}

html.page--categories .product_listing {
  margin-left: -15px;
  margin-right: -15px;
}

.page--categories .breadcrumb .widget-fullwidthimage img {
  border-radius: 0.25rem;
}

.product__related .productsgrid .productitem__baseprice > .price,
.combinesection__products .productsgrid .productitem__baseprice > .price {
  font-size: 16px;
}
.product__related .productsgrid .productitem__nowprice > span,
.combinesection__products .productsgrid .productitem__nowprice > span {
  font-size: 21px;
}

.card-columns .card {
  margin-bottom: 1.75rem;
}

.productsgrid a, .productsgrid a p, .productsgrid a:hover p,
.categorygrid a,
.categorygrid a p,
.categorygrid a:hover p {
  color: #58595b;
}
.productsgrid a:hover,
.categorygrid a:hover {
  text-decoration: none;
}

.categorygrid:empty {
  display: none;
}

.productsgrid__product {
  margin-bottom: 15px;
}
.productsgrid__product.ribbon .productitem:before {
  width: 101%;
  height: 25px;
  content: "";
  display: inline-block;
  position: absolute;
  bottom: -24px;
  right: -2px;
  z-index: 1;
  background-color: #d46a00;
  pointer-events: none;
  border-radius: 0 0 4px 4px;
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  text-align: center;
}
.productsgrid__product.ribbon-productofthemonth {
  margin-bottom: 37px;
}
.productsgrid__product.ribbon-productofthemonth .productitem {
  border-color: #d46a00;
}
.productsgrid__product.ribbon-productofthemonth .productitem:before {
  content: "PRODUCT VAN DE MAAND";
}

.productitem {
  font: inherit;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  border: none;
  padding: 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-radius: 3px;
}
.productitem .badge-danger {
  background-color: #d13539;
  position: absolute;
  left: -5px;
  padding: 6px 9px;
  border-radius: 3px;
  top: 5px;
  text-transform: uppercase;
  font-size: 14px;
}
.productitem:hover, .productitem:focus {
  text-decoration: none;
  color: inherit;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}
.productitem:hover .btn-primary,
.productitem:hover .btn-primary, .productitem:focus .btn-primary,
.productitem:focus .btn-primary {
  background-color: #ed7700;
  border-color: #ed7700;
}
.productitem .productitem__image {
  text-align: center;
  margin-bottom: 10px;
  min-height: 150px;
  display: flex;
  justify-content: center;
}
.productitem .productitem__image img {
  height: auto;
  align-self: center;
  max-height: 150px;
}
.productitem .card-body {
  padding: 0;
}
.productitem .card-body .productitem__description {
  font-size: 14px;
  line-height: 19px;
}
.productitem .card-body .productitem__description p {
  margin-bottom: 0;
}
.productitem .card-footer {
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 0 0 0;
  border-top: 0;
}
.productitem__title {
  font-size: 17px;
  padding-bottom: 5px;
}
.productitem__discountslogan {
  color: #7abb14;
  font-weight: 600;
}
.productitem__price {
  align-content: center;
}
.productitem__baseprice > .price {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  color: #929292;
  margin-right: 5px;
}
.productitem__baseprice > .price:before {
  content: "";
  position: absolute;
  top: 11px;
  left: 0px;
  width: 100%;
  height: 9px;
  background: transparent;
  border-top: 1px solid #929292;
  border-radius: 0;
  transform: rotate(-11deg);
}
.productitem__baseprice + .productitem__nowprice {
  padding-top: 0;
}
.productitem__nowprice > span {
  display: inline-block;
  font-size: 21px;
  font-weight: 600;
  color: #000;
}
@media (max-width: 565px) {
  .productitem__nowprice > span {
    margin-bottom: 10px;
  }
}
.productitem__baseprice > span.pricemultiple, .productitem__nowprice > span.pricemultiple {
  font-size: inherit;
  font-weight: inherit;
}
.productitem .btn {
  position: absolute;
  right: 0;
  bottom: 0;
}
.productitem__message {
  color: #4aa814;
  font-weight: 600;
}

.categoryitem {
  font: inherit;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border: none;
  padding: 15px;
}
.categoryitem:hover, .categoryitem:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  text-decoration: none;
  color: inherit;
}
.categoryitem .categoryitem__image {
  text-align: center;
  margin-bottom: 10px;
  min-height: 200px;
}
.categoryitem .card-body {
  padding: 1rem;
}
.categoryitem .card-footer {
  position: relative;
  border-top: 0;
  background-color: rgba(0, 0, 0, 0.03);
}
.categoryitem__title {
  font-size: 24px;
  padding-bottom: 5px;
}

@media (max-width: 565px) {
  .productsgrid__product {
    padding-right: 5px;
    padding-left: 5px;
  }
  .productsgrid__product .productitem {
    padding: 10px;
  }
  .productsgrid__product .productitem__image {
    min-height: 150px;
  }
  .productsgrid__product .productitem .btn {
    position: inherit;
    width: 100%;
  }
}