/* this block fixes top alignmenton of the label for an imgedit */
.wh-form__imgedit:before
{
  content: "\a0";
  visibility: hidden;
  display: block;
  height: 0
}
.wh-form__imgeditholder
{
  cursor: pointer;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.wh-form__imgeditimg
{
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width:100%;
  height:100%;
}
