@charset "UTF-8";
.wh-slider-holder {
  display: block;
  white-space: nowrap;
  padding: 0 0 30px;
  margin: 0;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.wh-slider-holder > .wh-slider {
  position: relative;
  height: 10px;
  background: #ebebeb;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #ebebeb), color-stop(100%, #f5f5f5));
  background: -webkit-linear-gradient(top, #ebebeb, #f5f5f5);
  background: linear-gradient(180deg, #ebebeb 0, #f5f5f5);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb",endColorstr="#f5f5f5",GradientType=0);
}
.wh-slider-holder > .wh-slider > .wh-slider-knob {
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0px;
  margin-top: -5px;
  margin-left: -10px;
  height: 21px;
  width: 21px;
  background-color: #d46a00;
  border-radius: 100%;
  z-index: 1; /* keep knobs on top of rangebar */
}
.wh-slider-holder > .wh-slider > .wh-slider-knob:after {
  content: "";
  display: block;
  position: absolute;
  left: 9px;
  top: 9px;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: #fff;
}
.wh-slider-holder > .wh-slider > .wh-slider-knob > span {
  position: absolute;
  top: 100%;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 5px;
  width: 100px;
  margin-left: -40px;
  text-align: center;
  display: block;
}
.wh-slider-holder > .wh-slider > .wh-slider-knob > span:before {
  content: "€";
}
.wh-slider-holder > .wh-slider > .wh-slider-rangebar {
  position: absolute;
  cursor: move;
  top: 0px;
  height: 10px;
  background-color: #3a3e3e;
}