.webshop--showcookiebar:after {
  content: "";
  background-color: rgba(0, 0, 0, 0.65);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1020;
  pointer-events: none;
}

.cookiebar__notice {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  min-width: 350px;
  z-index: 1030;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.5);
}
.cookiebar__notice .btn-link {
  color: #337ab7;
  vertical-align: baseline;
  border: 0;
  font-size: inherit;
}
.cookiebar__notice .accept-cookie {
  min-width: 200px;
  text-align: center;
}
@media (max-width: 575px) {
  .cookiebar__notice .accept-cookie {
    width: 100%;
  }
}
.cookiebar__notice a {
  text-decoration: underline;
  color: #337ab7;
}