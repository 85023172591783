.webshop-checkout__form:not(.webshop-checkout--cartisempty) .webshop-checkout--ifempty,
.webshop-checkout__form.webshop-checkout--cartisempty .webshop-checkout--ifnotempty {
  display: none;
}

.webshop-checkout__form:not(.webshop-checkout--offerloyaltypoints) .webshop-checkout__loyaltysection {
  display: none;
}

.wh-form__fieldline.webshop-checkout--unavailable {
  display: none !important;
}

html.webshop--priceonrequest .webshop-checkout--ifnotpriceonrequest,
html:not(.webshop--priceonrequest) .webshop-checkout--ifpriceonrequest {
  display: none;
}