html.webshop--isproductpage {
  overflow-x: hidden;
}
html.webshop--isproductpage .badge-danger {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #d40000;
}

.tab-section:before {
  display: block;
  content: " ";
  height: 150px; /* Give height of your fixed element */
  margin-top: -150px; /* Give negative margin of your fixed element */
  visibility: hidden;
}

.slick-track {
  margin-left: 0;
  max-width: 300px !important;
}

.slick-next {
  right: auto;
  z-index: 1;
  position: inherit;
  margin: 0 auto;
  margin-top: 20px;
}

.slick-prev {
  left: auto;
  z-index: 1;
  position: inherit;
  margin: 0 auto;
}

.slick-prev:before {
  font-family: fontAwesome;
  content: "\f0aa";
  color: #e7bd62;
}

.slick-next:before {
  font-family: fontAwesome;
  content: "\f0ab";
  color: #e7bd62;
}

.product__options .card {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-radius: 3px;
}
.product__options .card .card-body {
  padding: 20px;
}
.product__options .card .card-body #amount_container {
  max-width: 164px;
  min-width: 139px;
}
.product__options .card .card-body .in-decrease_amount .value_button {
  display: inline-block;
  border: 1px solid #ccc;
  margin: 0px;
  width: 45px;
  height: 46px;
  text-align: center;
  vertical-align: middle;
  padding: 12px 0;
  background: #fdf8f4;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.product__options .card .card-body .in-decrease_amount .value_button:hover {
  cursor: pointer;
}
.product__options .card .card-body .in-decrease_amount #decrease {
  border-right: none;
  border-radius: 3px 0 0 3px;
}
.product__options .card .card-body .in-decrease_amount #increase {
  border-left: none;
  border-radius: 0 3px 3px 0;
}
.product__options .card .card-body .in-decrease_amount input#amount {
  text-align: center;
  border: none;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin: 0px;
  width: 45px;
  height: 46px;
  position: relative;
  top: 2px;
}
.product__options .card .card-body .in-decrease_amount input#amount::-webkit-inner-spin-button, .product__options .card .card-body .in-decrease_amount input#amount::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.product__options h1 {
  font-size: 36px;
  color: #d46a00;
  margin-bottom: 0;
}
.product__options .product_ean {
  color: #999;
}
.product__options .readmore__prod {
  text-decoration: underline;
  margin-bottom: 20px;
  display: block;
  margin-top: 10px;
}
.product__options .webshop-product__optiontitle {
  float: left;
  width: 100px;
  font-weight: 400;
  margin-bottom: 0;
}
.product__options .webshop-product__listprice {
  font-size: 19px;
  font-weight: 400;
  color: #d40000;
  position: relative;
}
.product__options .webshop-product__listprice:before {
  content: "";
  position: absolute;
  top: 11px;
  left: 0px;
  width: 100%;
  height: 9px;
  background: transparent;
  border-top: 1px solid #d40000;
  border-radius: 0;
  transform: rotate(-11deg);
}
.product__options .product__quantitydiscounts {
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  border-radius: 3px;
}
.product__options .product__quantitydiscounts h4 {
  font-size: 21px;
  margin-bottom: 0;
}
.product__options .product__quantitydiscounts .quantitydiscounts_percentage {
  color: #7abb14;
  font-weight: 600;
}
.product__options .webshop-product__price {
  font-size: 28px;
  font-weight: 600;
  color: #000;
}
.product__options .btn-lg {
  font-size: 1rem;
  padding: 10px 20px;
}
.product__options .product__discountslogan {
  margin-top: 15px;
  color: #7abb14;
  font-weight: 700;
}
.product__options .product_promoimage > img {
  max-width: 100%;
  height: auto;
}

.product__info {
  margin-top: 20px;
}
.product__info .nav-link {
  color: #000;
  font-weight: 400;
}
.product__info .tab-section {
  margin-bottom: 50px;
}
.product__info .tab-section .table-striped tbody tr:nth-of-type(odd) {
  background-color: #fdf8f4;
}
.product__info .tab-section .table td,
.product__info .tab-section .table th {
  border-bottom: 1px solid #dee2e6;
  border-top: none;
}
.product__info .tab-section .table td:first-child,
.product__info .tab-section .table th:first-child {
  border-right: 1px solid #dee2e6;
}
.product__info .tab-section.documents a {
  color: #444444;
}
.product__info .tab-section.documents i {
  color: #d40000;
}
.product__info h2 {
  font-size: 21px;
  font-weight: 400;
}
.product__info .nav-pills {
  border-bottom: 1px solid #ccc;
  position: relative;
}
.product__info .nav-pills:before {
  position: absolute;
  content: "";
  bottom: -1px;
  left: -355px;
  width: 1920px;
  height: 1px;
  z-index: 1;
  background-color: #ccc;
}
@media (max-width: 577px) {
  .product__info .nav-pills:before {
    width: 100%;
    left: 0;
  }
}
.product__info .nav-pills .nav-link {
  padding: 10px 14px;
}
.product__info .nav-pills .nav-item {
  position: relative;
  top: 1px;
  border-bottom: 1px solid #ccc;
}
.product__info .nav-pills .nav-item.active {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #fff;
  position: relative;
  z-index: 1;
}

.webshop-product__stockinfo {
  color: #00b900;
  text-align: right;
  margin-top: 10px;
  font-weight: 600;
}
.webshop-product__stockinfo span {
  font-weight: 400;
}

.product__images__preview {
  text-align: center;
  display: flex;
  justify-content: center;
  min-height: 400px;
}
.product__images__preview a {
  display: inline-flex;
}
.product__images__preview img {
  height: auto;
  align-self: center;
}

.product__images__grid {
  margin-top: 10px;
  max-height: 150px;
  margin-bottom: 10px;
}
.product__images__grid .product__image {
  cursor: pointer;
  margin-right: 10px;
  min-height: 100px;
  display: flex !important;
  max-width: 100px;
  border: 1px solid #ccc;
  padding: 5px;
}
.product__images__grid .product__image a {
  display: inline-flex;
}
.product__images__grid .product__image a img {
  width: auto;
  align-self: center;
}

@media (max-width: 577px) {
  .product__images {
    margin: 20px 0;
  }
  .product__images__preview img {
    max-height: 250px;
  }
  .product__images .slick-next,
  .product__images .slick-prev {
    top: 100%;
    margin-top: 15px;
    z-index: 1;
  }
  .product__images .slick-next {
    right: 0px;
  }
  .product__images .slick-prev {
    left: 0px;
  }
}
.slick-next:before, .slick-prev:before {
  color: #d46a00;
}

.product__tags {
  margin-top: 20px;
  margin-bottom: 20px;
}

.product__tag {
  background: transparent;
  padding: 6px 10px;
  display: inline-block;
  margin: 2px;
  color: #bbb;
  border-radius: 3px;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out 0s;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-weight: 400;
  text-decoration: none;
}

.product__tag:hover {
  background: #fff;
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: inherit;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}

.product__related {
  margin-top: 15px;
}

@media (min-width: 480px) and (max-width: 979px) {
  .webshop-product__optiontitle {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media (max-width: 480px) {
  .nav-link {
    display: block;
    padding: 0.5rem;
  }
  .webshop-product__optiontitle {
    width: 100%;
    margin-bottom: 10px;
  }
  .product__options {
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) {
  .product__options {
    position: -webkit-sticky;
    position: sticky;
    top: 150px;
    z-index: 1;
  }
  .webshop-product__priceblock__column {
    min-width: 139px;
  }
}
.product__related .product_listing {
  padding: 0;
}
@media (max-width: 576px) {
  .product__related .product_listing {
    margin: 0;
  }
  .product__related .product_listing .col-lg-4 {
    width: 100%;
  }
}
@media only screen and (min-width: 577px) and (max-width: 768px) {
  .product__related .product_listing .col-lg-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 992px) {
  .product__related .product_listing .col-lg-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 1200px) {
  .product__related .product_listing .col-lg-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 1400px) {
  .product__related .product_listing .col-lg-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}