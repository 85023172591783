li.webshop-pagenav {
  list-style: none;
  display: inline-block;
  border: 1px solid #ddd;
  padding: 5px 10px;
  text-align: center;
}

li.webshop-pagenav + li.webshop-pagenav {
  border-left: none;
}

li.webshop-pagenav--current {
  background: #337ab7;
  color: #fff;
}

li.webshop-pagenav:not(.webshop-pagenav--current):hover {
  background: #ddd;
}

li.webshop-pagenav:not(.webshop-pagenav--current):hover a {
  text-decoration: none;
}

li.webshop-pagenav:first-child:not(.webshop-pagenav--first) .webshop-pagebuton__pagenr {
  display: none;
}
li.webshop-pagenav:first-child:not(.webshop-pagenav--first):before {
  content: "...";
}

li.webshop-pagenav:last-child:not(.webshop-pagenav--last) .webshop-pagebuton__pagenr {
  display: none;
}
li.webshop-pagenav:last-child:not(.webshop-pagenav--last):before {
  content: "...";
}