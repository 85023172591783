html.webshop--ishomepage header.stickynav .topnav {
  margin-top: 9px;
}

header {
  position: relative;
  background-color: #fff;
  transition: 0.2s;
  z-index: 1;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  header .container:first-child {
    padding-left: 0;
  }
}
header.stickynav {
  position: sticky;
  top: 0;
  z-index: 3;
  max-width: 1918px;
  width: 100%;
}
header.stickynav .logo img {
  width: 250px;
}
header.stickynav .toprightpanel {
  margin-top: 0px;
}
header.stickynav .homenav {
  display: none;
}
header.stickynav #mainnav .toplevelmenu {
  display: block;
}
header:after {
  clear: both;
  display: block;
  content: "";
}
header .logo {
  display: inline-block;
  margin: 10px 0;
  transition: 0.2s;
}
header .logo > img {
  height: auto;
  width: auto;
  width: 250px;
}
header .homenav {
  position: absolute;
  z-index: 1;
  top: -1px;
  min-width: 270px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  header .homenav {
    display: none;
  }
}
header .homenav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
header .homenav li a {
  color: #444444;
}
header .homenav li a:before {
  content: "\f105";
  display: inline-block;
  font-family: fontAwesome;
  color: #d46a00;
  font-size: 16px;
  padding-right: 5px;
}
header .topnav {
  margin-bottom: 0;
  float: right;
}
header .topnav a {
  padding: 0 0.5rem;
  color: #444444;
}
header .toprightpanel .myaccount {
  margin-right: 10px;
}
header .toprightpanel i {
  color: #d46a00;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  header .toprightpanel i {
    font-size: 24px;
    position: relative;
    top: 7px;
    right: 5px;
  }
}
header .toprightpanel a {
  color: #444444;
}
header .toprightpanel .list-inline-item:not(:last-child) {
  margin-right: 0;
}
header .toprightpanel .list-inline-item {
  position: relative;
}
header .toprightpanel .badge {
  background: #d46a00;
  position: absolute;
  margin-left: -10px;
  top: -8px;
  right: -12px;
  font-size: 12px;
  line-height: 12px;
  padding: 4px 4px 4px 4px;
  min-width: 20px;
  font-family: arial, helvetica, sans-serif;
}
header .topmidpanel #topsearchform {
  margin-top: 20px;
  white-space: nowrap;
  transition: 0.2s;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
header .topmidpanel #topsearchform input {
  border-left: 1px solid #ced4da;
  border-top: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  border-right: 0;
  outline: none;
  -webkit-appearance: none;
  max-width: 350px;
}
header .topmidpanel #topsearchform input::placeholder {
  color: #999;
  font-weight: 300;
  font-size: 16px;
}
header .topmidpanel #topsearchform button {
  border-right: 1px solid #ced4da;
  border-top: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  color: #444444;
}
@media (max-width: 1920px) {
  header.stickynav {
    left: 0;
  }
}
@media (max-width: 991px) {
  header .toprightpanel {
    border-right: 0 none;
    border-left: 0 none;
    background-color: transparent;
    padding-right: 0;
    padding-bottom: 0;
    display: flex;
  }
  header .toprightpanel:before,
  header .toprightpanel .topnav {
    display: none;
  }
  header .toprightpanel .basket .label {
    display: none;
  }
}
@media (max-width: 767px) {
  header .logo > img {
    max-width: 250px;
  }
  header.stickynav {
    position: sticky;
    top: 0;
    z-index: 3;
  }
  header.stickynav .logo img {
    max-width: 250px;
  }
  header.stickynav .toprightpanel {
    margin-top: 0px;
  }
  header .toprightpanel {
    padding-top: 0;
  }
}
@media (max-width: 565px) {
  header .logo > img {
    max-width: 200px;
  }
  header.stickynav .logo img {
    max-width: 200px;
  }
  header .list-inline-item:first-child {
    margin-right: 0;
  }
  header .toprightpanel {
    margin-top: 0;
    text-align: right;
  }
}
header .navbar-dark {
  clear: both;
}
header .navbar {
  padding: 0;
  position: relative;
  min-height: 43px;
}
header .navbar-brand {
  font-size: 1rem;
}
header .navbar-dark .navbar-toggler {
  border-color: rgba(0, 0, 0, 0);
  padding: 0;
  color: #444444;
}
header .navbar-dark .navbar-toggler-icon {
  background-image: url(../web/img/toggler-icon.svg);
  width: 16px;
  height: 12px;
  position: relative;
  margin-right: 5px;
  top: -1px;
}
header .navbar-dark .navbar-toggler-txt {
  font-size: 16px;
}
header #navbar-wrapper {
  clear: both;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
@media only screen and (min-width: 320px) and (max-width: 565px) {
  header #navbar-wrapper .container {
    padding-right: 5px;
    padding-left: 5px;
  }
}
header #mainnav {
  width: 100%;
  max-height: 42px;
}
header #mainnav .toplevelmenu__container {
  min-width: 270px;
  display: inline-block;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
header #mainnav .toplevelmenu__container .main.nav-link {
  font-weight: 600;
  font-size: 18px;
}
header #mainnav .toplevelmenu__container .main.nav-link i {
  color: #d46a00;
}
header #mainnav .toplevelmenu__container .toplevelmenu {
  margin: 0 auto;
  width: 190px;
  padding: 0;
}
header #mainnav .toplevelmenu__container li {
  display: block;
  float: left;
}
header #mainnav .toplevelmenu__container li ul {
  min-width: 270px;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  left: 0;
  position: absolute;
  background-color: #d46a00;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: visibility 0s, opacity 0.3s linear;
}
header #mainnav .toplevelmenu__container li ul li {
  position: relative;
}
header #mainnav .toplevelmenu__container ul li a {
  display: block;
  padding: 0.6rem;
  text-decoration: none;
  white-space: nowrap;
  color: #444444;
}
header #mainnav .toplevelmenu__container li li a {
  color: #fff;
}
header #mainnav .toplevelmenu__container li:hover > ul {
  visibility: visible;
  position: absolute;
  opacity: 1;
  margin-left: 0;
  padding-left: 0;
}
header #mainnav .toplevelmenu__container li:hover li {
  float: none;
}
header #mainnav .toplevelmenu__container li:hover li a:hover {
  background: #444444;
  color: #fff;
}
header #mainnav .toplevelmenu__container .main-navigation li ul li {
  border-top: 0;
}
header #mainnav .toplevelmenu__container ul ul ul {
  left: 100%;
  top: 0;
}
header #mainnav .toplevelmenu__container ul:before,
header #mainnav .toplevelmenu__container ul:after {
  content: " ";
  display: table;
}
header #mainnav .toplevelmenu__container ul:after {
  clear: both;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  header #mainnav .toplevelmenu__container {
    display: none;
  }
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
  .homenav {
    display: none;
  }
  #topsearchform {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }
  #topsearchform .form-control {
    font-size: 13px;
    border-right: none;
  }
  #topsearchform .btn-link {
    color: #444;
    font-size: 18px;
    padding: 0px 8px;
    border: 1px solid #ced4da;
    border-left: none;
  }
}