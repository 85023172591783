html.webshop--ishomepage h1 b {
  font-weight: 500;
  font-size: 32px;
}

html.webshop--ishomepage .toplevelmenu {
  list-style: none;
  padding: 0;
  margin: 0 20px 0 15px;
  position: relative;
  z-index: 100;
  display: none;
}

html.webshop--ishomepage .container-fluid {
  padding: 0;
}
html.webshop--ishomepage .main--margintop {
  margin-top: 0;
  width: 100%;
}
html.webshop--ishomepage .product_listing {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}
@media (max-width: 576px) {
  html.webshop--ishomepage .product_listing {
    margin: 0;
  }
  html.webshop--ishomepage .product_listing .col-lg-4 {
    width: 100%;
  }
}
@media only screen and (min-width: 577px) and (max-width: 768px) {
  html.webshop--ishomepage .product_listing .col-lg-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 992px) {
  html.webshop--ishomepage .product_listing .col-lg-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 1200px) {
  html.webshop--ishomepage .product_listing .col-lg-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 1400px) {
  html.webshop--ishomepage .product_listing .col-lg-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}