footer {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  background-color: #fff;
}
footer #ondermenu {
  margin-bottom: 20px;
  padding: 50px 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
footer #ondermenu .row--cols > div {
  margin-bottom: 20px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  footer #ondermenu .row--cols > div {
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }
  footer #ondermenu .row--cols > div:last-child {
    border-bottom: none;
  }
}
footer #ondermenu .footer__widget a {
  color: #d46a00;
}
footer #ondermenu .footer__widget ul {
  display: table;
  list-style: none;
  padding: 0;
  margin: 0 -7.5px 0 -7.5px;
}
footer #ondermenu .footer__widget li {
  display: table-row;
}
footer #ondermenu .footer__widget li a {
  color: #444444;
}
footer #ondermenu .footer__widget li:before {
  content: "\f105";
  width: 1.5em;
  font-size: 16px;
  line-height: 28px;
  font-family: FontAwesome;
  display: table-cell;
  padding-left: 7px;
  color: #d46a00;
}
footer #ondermenu .footer__widget .footer__social i {
  font-size: 28px;
  margin-right: 10px;
}
footer #ondermenu .footer__widget .footer__newsletter {
  background-color: #fdf8f4;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 20px;
}
footer .bottom__logos {
  padding: 5px 0 40px 0;
  border-bottom: 1px solid #ccc;
}
footer .bottom__logos .payment__logos {
  display: inline;
}
footer .bottom__logos .payment__logos img {
  max-height: 50px;
  width: auto;
  max-width: 100px;
  margin-right: 20px;
}
footer .bottom__line img {
  position: relative;
  top: -4px;
}
footer .bottomnav ul {
  list-style-type: none;
  margin: 0;
  padding: 0 15px;
}
footer .bottomnav ul li {
  display: inline-block;
}
footer .bottomnav ul li a {
  color: #58595b;
}
footer .bottomnav ul li + li:before {
  content: " - ";
  padding: 0 6px;
  display: inline-block;
}
footer h5 {
  display: block;
  margin: 0 0 10px;
}

.grecaptcha-badge {
  visibility: hidden;
}